const Setting = {
  /**
   * 基础配置
   * */
  // 项目的 ID
  appID: process.env.VUE_APP_ID,
  // 网页标题的后缀，不需要设置为 false 或空
  titleSuffix: process.env.VUE_APP_TITLE,
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  // 应用的基路径
  routerBase: process.env.VUE_APP_PUBLIC_PATH,
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  // Cookies 默认保存时间，单位：天，0 为 session
  cookiesExpires: 1,
}

export default Setting