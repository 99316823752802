import request from '@/plugins/request'

// 首页-推荐文章列表
export function getHomeArticlePage (data) {
  return request({
      url: '/home/article/page',
      method: 'post',
      data
  })
}

// 删除附件-逻辑删除
// export function deleteAttach (data) {
//   return request({
//       url: '/system/attach/delete',
//       method: 'post',
//       data
//   })
// }

// 词条
export function getEntryList (params) {
  return request({
    url: '/home/entry/list',
    method: 'get',
    params
  });
}

// 历史浏览记录列表
export function getHistoryList (params) {
  return request({
    url: '/home/history/list',
    method: 'get',
    params
  });
}

// 热门搜索列表
export function getHotSearchList (params) {
  return request({
    url: '/home/hot/search/list',
    method: 'get',
    params
  });
}

// 热门搜索词列表
export function getHotSearchEnterList (params) {
  return request({
    url: '/home/hot/search/enter/list',
    method: 'get',
    params
  });
}
