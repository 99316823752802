import request from '@/plugins/request'

// 搜索页
export function getSearchData (data) {
  return request({
      url: '/search/execute',
      method: 'post',
      data
  })
}

// 文章详情-根据id
export function getDetail (params) {
  return request({
      url: '/article/detail/byId',
      method: 'get',
      params
  })
}

// 文章列表
export function getArticlePage (data) {
  return request({
      url: '/index/complexRelationshipPage',
      method: 'post',
      data
  })
}

// // 文章列表-高级检索
// export function getArticleAdvancedPage (data) {
//   return request({
//       url: '/brows/article/advanced/page',
//       method: 'post',
//       data
//   })
// }

// 分类
export function getCategory (params) {
  return request({
      url: '/brows/category',
      method: 'get',
      params
  })
}

// 临床试验列表
// export function getClinicalexperimentPage (data) {
//   return request({
//       url: '/brows/clinicalexperiment/page',
//       method: 'post',
//       data
//   })
// }

// // 临床试验列表-高级检索
// export function getClinicalexperimentAdvancedPage (data) {
//   return request({
//       url: '/brows/clinicalexperiment/advanced/page',
//       method: 'post',
//       data
//   })
// }

// // 饮食指南列表
// export function getDietaryguidelinesPage (data) {
//   return request({
//       url: '/brows/dietaryguidelines/page',
//       method: 'post',
//       data
//   })
// }

// // 饮食指南列表-高级检索
// export function getDietaryguidelinesAdvancedPage (data) {
//   return request({
//       url: '/brows/dietaryguidelines/advanced/page',
//       method: 'post',
//       data
//   })
// }

// 相关数据
export function getRelatedDataList (data) {
  return request({
      url: '/brows/related/dataList',
      method: 'post',
      data
  })
}

// 相关数据-高级检索
export function getRelatedAdvancedDataList (data) {
  return request({
      url: '/brows/related/advanced/dataList',
      method: 'post',
      data
  })
}

// 顶部信息相关数据-统计
export function getRelatedTopDataList (data) {
  return request({
      url: '/brows/top/statics',
      method: 'post',
      data
  })
}

// 相关统计
export function getRelatedStatics (params) {
  return request({
      url: '/brows/related/statics',
      method: 'get',
      params
  })
}

// 顶部信息
export function getTopInfo (params) {
  return request({
      url: '/brows/top/info',
      method: 'get',
      params
  })
}

// 食品化合物关系分页列表
export function getFoodCompoundRelationPage (data) {
  return request({
      url: '/brows/foodCompoundRelation/page',
      method: 'post',
      data
  })
}

// 检索数据分页列表
export function getSearchDataPage (params) {
  return request({
    url: '/search/data/page',
    method: 'get',
    params
  });
}

// 获取关联关系Tab
export function getRelationship (params) {
  return request({
    url: '/brows/getRelationship',
    method: 'get',
    params
  });
}

// 获取关联关系Tab分页数据
export function getRelationshipPage (data) {
  return request({
    url: '/brows/getRelationship/page',
    method: 'post',
    data
  });
}

// 相关数据-统计关系
export function getRelationNum (data) {
  return request({
    url: '/brows/related/dataList/RelationNum',
    method: 'post',
    data
  });
}

// 关系类型列表 relationRole下拉框数据
export function getRelationRoleList (data) {
  return request({
    url: '/index/relationRoleList', 
    method: 'post',
    data
  });
}

// 词表数据-统计 简单搜索-饼图数据
export function getEntryStatics (data) {
  return request({
    url: '/index/entryStatics',
    method: 'post',
    data
  });
}

// 关系类型-统计 高级搜索-饼图数据
export function getRelationRoleStatics (data) {
  return request({
    url: '/index/relationRoleStatics',
    method: 'post',
    data
  });
}