<template>
  <div class="search-basic flex-between">
    <Select v-model="currentValue" style="width:200px" placeholder="" size="large">
      <Option v-for="item in list" :value="item.value" :key="item.value">{{ item.name }}</Option>
    </Select>
    <div class="vertical-line"></div>
    <div class="search-basic-select">
      <Select
        v-model="searchWords"
        filterable
        :remote-method="(query) => debouncedRemoteMethod(query)"
        :loading="loading"
        placeholder="Search By: Disease name, Food name, Comopound name, Dietary Pattern, or other factors..."
        @on-change="handleSearch"
        @keyup.enter="handleSearch"
      >
        <Option v-for="(option, index) in searchWordList" :value="option.value" :label="option.value" :key="index">
          <span>{{option.label}}</span>
          <span style="float:right;color:#ccc">{{ option.count }}</span>
        </Option>
      </Select>
      <Icon type="md-search" @click="handleSearch" />
    </div>
    <Button class="ivu-ml-8" shape="circle" size="large" @click="gotoAdvancedSearch">Advanced Search</Button>
  </div>
</template>
<script>
  import { getEntryList } from '@/api/home'
  import _ from 'lodash'
  import {
    getSearchDataPage
  } from '@/api/search'
  export default {
    name: 'SearchBasic',
    props: {
      defaultValue: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      isNeedReturnType: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        loading: false,
        list: [],
        currentValue: '',
        searchWords: '',
        searchWordList: []
      }
    },
    watch: {
      defaultValue: {
        immediate: true, // 初始化时立即触发
        handler(n, o) {
          this.searchWords = n
        }
      },
      type: {
        immediate: true,
        handler(n, o) {
          if (n) {
            this.currentValue = n
          }
        }
      }
    },
    created () {
      this.loadEntryList()
    },
    methods: {
      loadEntryList () {
        this.$store.dispatch('admin/entry/saveEntryDataAsync').then(res => {
          this.list = res
          if (!this.type) {
            this.currentValue = this.list.length ? this.list[0].value : ''
          }
          if (this.isNeedReturnType && this.list.length) {
            this.$emit('returnType', this.list[0])
          }
        })
      },
      handleSearch () {
        if (!this.searchWords) {
          this.$Message.error('请输入搜索关键词！')
          return
        }
        const typeObj = this.list.find(item => item.value === this.currentValue)
        const searchwordObj = this.searchWordList.find(item => item.prefLabel === this.searchWords)
        const params = {
          type: this.currentValue,
          typeName: typeObj.name,
          primaryKeyName: typeObj.primaryKeyName,
          searchWords: this.searchWords,
          primaryKeyId: searchwordObj ? searchwordObj[typeObj.primaryKeyName] : ''
        }
        this.$emit('search', params)
      },
      gotoAdvancedSearch () {
        this.$router.push('/advancedSearch')
      },
      // 防抖
      debouncedRemoteMethod: _.debounce(function(query) {
        this.remoteMethod(query)
      }, 500),
      remoteMethod (query) {
        if (query !== '') {
          this.loading = true
          const params = {
            pageNum: 1,
            pageSize: 100,
            key: this.currentValue,
            searchValue: query
          }
          getSearchDataPage(params).then(res => {
            if (res.status === 1) {
              this.searchWordList = res.body.records.map(item => {
                item.value = item.prefLabel
                item.label = item.prefLabel
                return item
              })
            }
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
        } else {
            this.searchWordList = [];
        }
      },
      handleCreate (val) {
        this.searchWordList.push({
          value: val,
          label: val
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .search-basic {
    width: 820px;
    margin: 0 auto;
    padding: 10px 0;
    gap: 15px;
    /deep/ .ivu-select-selection,
    /deep/ .ivu-input {
      border-color: #fff !important;
      font-size: 12px;
    }
    /deep/ .ivu-input-search-icon {
      font-size: 24px;
      color: rgba(36,104,242,1);
      &:hover {
        color: rgba(36,104,242,0.6);
      }
    }
    .search-basic-select {
      position: relative;
      width: 100%;
      /deep/ .ivu-select-selection {
        border-color: #fff !important;
        font-size: 12px;
        height: 40px;
        padding-right: 40px;
      }
      /deep/ .ivu-icon-md-search {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 24px;
        color: rgba(36,104,242,1);
        &:hover {
          color: rgba(36,104,242,0.6);
          cursor: pointer;
        }
      }
    }
  }
  .vertical-line {
    // display: inline-block;
    width: 1px;
    height: 40px;
    background-color: #F2F2F2;
  }
</style>
