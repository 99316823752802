import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import ViewUIPlus from 'view-ui-plus'
import store from '@/store'

// 不需要鉴权的路由
const whiteRoutes = ['/login', '/auth', '/403', '/404', '/500']

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})

/**
 * 路由拦截
 */
router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start()
  if (!whiteRoutes.includes(to.path)) {
    store.dispatch('admin/account/refreshAccessToken')
  }
  next()
})

router.afterEach(() => {
  ViewUIPlus.LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
