/***********
 * 搜索类型
 */
export const tabList = [
  {
    name: 'Disease',
    value: 'disease'
  },
  {
    name: 'Food',
    value: 'food'
  },
  {
    name: 'Compound',
    value: 'compound'
  }
]
/***********
 * 图表颜色
 */
export const colorList = [
  {
    key: '1000001',
    color: '#c280ff'
  },
  {
    key: '1000002',
    color: '#3ac17f'
  },
  {
    key: '1000003',
    color: '#f9b020'
  }
]

export const threeColors = {
  '1000001': '#c280ff',
  '1000002': '#3ac17f',
  '1000003': '#f9b020',
  '1411496206458880': '#6294fa',
  '1411546033864704': '#74caed'
}

export const colors = ['#c280ff', '#3ac17f', '#f9b020', '#6294fa', '#74caed', '#caf982', '#facd91', '#80ffff']
