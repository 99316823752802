import { getEntryList } from '@/api/home'
import { getChartColors } from '@/libs'

export default {
  namespaced: true,
  state: {
    entryData: []
  },
  mutations: {
    SAVE_ENTRYDATA (state, list) {
      state.entryData = list
    }
  },
  actions: {
    // 获取动态词条数据
    saveEntryDataAsync({ commit }) {
      return new Promise((resolve, reject) => {
        getEntryList().then(res => {
          let tabs = []
          if (res.status === 1) {
            const entryColors = getChartColors(res.body.length)
            tabs = res.body.map((item, index) => {
              item.name = item.label
              item.value = item.key
              item.color = entryColors[index]
              return item
            })
          }
          // 传递处理后的 tabs 到 mutation
          commit('SAVE_ENTRYDATA', tabs)
          localStorage.setItem('entryData', JSON.stringify(tabs))
          resolve(tabs)
        }).catch(error => {
          reject(error)
        })
      })      
    }
  }
}