/**
 * 用户信息
 * */
import util from '@/libs/util'
export default {
    namespaced: true,
    state: {
        // 用户信息
        info: {},
        // 用户角色
        roles: [],
        // 用户名称
        userName: util.cookies.get('userName') || ''
    },
    mutations: {
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_INFO: (state, info) => {
            state.info = info;
        },
        SET_USERNAME: (state, info) => {
            state.userName = info.name
        }
    },
    actions: {
        /**
         * @description 设置用户数据
         * @param {Object} state vuex state
         * @param {Object} commit vuex commit
         * @param {Object} dispatch vuex dispatch
         * @param {*} info info
         */
        set ({ commit, dispatch }, info) {
            return new Promise(async (resolve) => {
                // store 赋值
                commit('SET_ROLES', info.roles);
                commit('SET_INFO', info);
                commit('SET_USERNAME', info)
                // 持久化
                await dispatch(
                    'admin/db/set',
                    {
                        dbName: 'sys',
                        path: 'user.info',
                        value: info,
                        user: true
                    },
                    { root: true }
                );
                // end
                resolve();
            });
        },
        /**
         * @description 从数据库取用户数据
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         */
        load ({ state, dispatch }) {
            return new Promise(async (resolve) => {
                // store 赋值
                state.info = await dispatch(
                    'admin/db/get',
                    {
                        dbName: 'sys',
                        path: 'user.info',
                        defaultValue: {},
                        user: true
                    },
                    { root: true }
                );
                // end
                resolve();
            });
        }
    }
};
