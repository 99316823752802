// import { getNativeMenuSider } from '@/libs/system'
import { colors, threeColors } from '@/libs/selectionMap'

// 转换字符串，undefined,null等转化为""
export function parseStrEmpty (str) {
  if (!str || str === 'undefined' || str === 'null') {
    return ''
  }
  return str
}

// 顶栏菜单redirect
// export function getRedirect (header) {
//   const siderMenu = treeFlat(getNativeMenuSider(), [])
//   return siderMenu.find((el) => el.header === header).path
// }

// 根据route path 获取route name
export function getPathName (item) {
  let result = item.path.split('/').join('-')
  if (result.startsWith('-')) {
    result = result.substring(1)
  }
  return result
}

// menu children 增加  header字段，以供筛选
function treeFlat (treeList = [], menuList = []) {
  treeList.forEach((item) => {
    if (item.children && item.children.length > 0) {
      const children = [...item.children]
      treeFlat(
        children.map((el) => {
          return {
            ...el,
            header: item.header
          }
        }),
        menuList
      )
    } else {
      if (item.path) {
        menuList.push({
          title: item.title,
          path: item.path,
          header: item.header
        })
      }
    }
  })
  return menuList
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams (params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== '' &&
            typeof value[key] !== 'undefined'
          ) {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

// 验证是否为 blob 格式
export async function blobValidate (data) {
  try {
    const text = await data.text()
    JSON.parse(text)
    return false
  } catch (error) {
    return true
  }
}

// 判断用户是否有按钮权限
export function hasAccess (accessNeeded = '') {
  const userAccess = this.$store.state.admin.user.info.access || []
  // console.log('用户权限', userAccess, accessNeeded, userAccess.includes(accessNeeded))
  return userAccess.includes(accessNeeded)
}

// 格式化日期筛选参数  传入日期区间数组 并改变结束时间为当天的23:59:59
export function formatQueryDate (params, dateArr, startKey, endKey) {
  params[startKey] =
    dateArr.length === 2 && dateArr[0] !== '' && dateArr[1] !== ''
      ? this.$Date(dateArr[0]).format('YYYY-MM-DD') + ' 00:00:00'
      : ''
  params[endKey] =
    dateArr.length === 2 && dateArr[0] !== '' && dateArr[1] !== ''
      ? this.$Date(dateArr[1]).format('YYYY-MM-DD') + ' 23:59:59'
      : ''
}

// 根据根节点id将一个一维数组格式化为树形数据
/**
 * 根据根节点id将一个一维数组格式化为树形数据
 * list 一维数组
 * rootId 父id
 * options 一个对象 需要使用的属性 固定格式 如{pid: 'parentId', id: 'id'}
 */
export function formatTreeData (list, rootId, options) {
  let newList = []
  list.forEach(item => {
    if (item[options.pid] + '' === rootId + '') {
      const children = this.formatTreeData(list, item[options.id], options)
      if (children.length) {
        item.children = children
      }
      newList.push(item)
    }
  })
  return newList
}

/**
 * 树形数据扁平化
 * data tree数组
 */
export function treeToFlat (data, ary) {
  // let ary = []
  data.forEach(item => {
    ary.push(item)
    if (item.children && item.children.length) {
      treeToFlat([...item.children], ary)
    }
  })
  return ary
}

/**
 * 创建随机id
 */
export function createRandomId (length = 10) {
  return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
}
// 将不符合json字符串格式的字符串格式化成标准数组
export function formatterArray (text) {
  const interventionType = text.replace(/\'/g, '').replace(/\]/g, '').replace(/\[/g, '')
  return interventionType.split(',')
}
// 将不符合json字符串格式的字符串格式化成标准数组对象
export function formatterArrayObject (text) {
  // const objectArr = []
  const newText = text.replace(/\'/g, '').replace(/\"/g, '').replace(/\]/g, '').replace(/\[/g, '').replace(/\{/g, '').replace(/\}/g, '')
  const arr = newText.split(',')
  // arr.forEach(item => {
  //   const itemArr = item.split(':')
  //   objectArr.push({
  //     `${itemArr[0]}`: itemArr[1]
  //   })
  // })
  return arr
}

/**
* 设置图表的颜色
* @param {*} count 
*/
export function getChartColors (count) {
 let chartColors = []
 const colorsLen = colors.length
  if (count > colorsLen) {
    const uniqueColors = generateUniqueColors(count - colorsLen)
    chartColors = colors.concat(uniqueColors)
  } else {
    chartColors = colors.slice(0, count)
  }
  return chartColors
}

/**
 * 生成图表颜色数组
 */
export function generateRandomColor() {
  // 生成随机的 RGB 值
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r},${g},${b})`;
}

export function generateUniqueColors(count) {
  const colors = [];
  while (colors.length < count) {
      let newColor = generateRandomColor();
      if (!colors.includes(newColor)) {
          // 确保颜色之间的区分度，这里可以加入更多的逻辑来增加颜色之间的差异
          let isDistinct = true;
          for (let color of colors) {
              let [r1, g1, b1] = newColor.match(/\d+/g).map(Number);
              let [r2, g2, b2] = color.match(/\d+/g).map(Number);
              // 确保每个颜色通道至少相差一定的数值，以增加区分度
              if ((Math.abs(r1 - r2) < 40) && (Math.abs(g1 - g2) < 40) && (Math.abs(b1 - b2) < 40)) {
                  isDistinct = false;
                  break;
              }
          }
          if (isDistinct) {
              colors.push(newColor);
          }
      }
  }
  return colors;
}

export function formatFirstChart(text) {
  const textArr = text.split('_')
  const lastTextArr = textArr.map((t, i) => {
    if (i > 0) {
      return t.charAt(0).toUpperCase() + t.slice(1)
    }
    return t
  })
  return lastTextArr.join('')
}

export function getKeyColor (entryData, key) {
  const target = entryData.find(entry => entry.key === key)
  // const color = target ? target.color : '#CFF5D8'
  const color = target ? target.color : '#FB6767'
  return color
}