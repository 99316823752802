import BasicLayout from '@/layouts/basic-layout'

const routes = [
  {
    path: '/',
    // name: 'home',
    redirect: 'index',
    component: BasicLayout,
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: 'index',
          auth: true,
          cache: true
        },
        component: () => import('@/views/home/index')
      },
      // {
      //   path: 'browse',
      //   name: 'browse',
      //   meta: {
      //     title: 'browse',
      //     auth: true,
      //     cache: true
      //   },
      //   component: () => import('@/views/browse/index')
      // },
      // {
      //   path: 'statistics',
      //   name: 'statistics',
      //   meta: {
      //     title: 'statistics',
      //     auth: true,
      //     cache: true
      //   },
      //   component: () => import('@/views/statistics/index')
      // },
      // {
      //   path: 'search',
      //   name: 'search',
      //   meta: {
      //     title: 'search',
      //     auth: true,
      //     cache: true
      //   },
      //   component: () => import('@/views/search/index')
      // },
      {
        path: 'detail',
        name: 'detail',
        meta: {
          title: 'detail',
          auth: true,
          cache: true
        },
        component: () => import('@/views/home/detail')
      },
      // {
      //   path: 'advancedSearch',
      //   name: 'advancedSearch',
      //   meta: {
      //     title: 'advancedSearch',
      //     auth: true,
      //     cache: true
      //   },
      //   component: () => import('@/views/advancedSearch/index')
      // },
      // {
      //   path: 'statisticsDisease',
      //   name: 'statisticsDisease',
      //   meta: {
      //     title: 'statisticsDisease',
      //     auth: true,
      //     cache: true
      //   },
      //   component: () => import('@/views/statisticsDisease/index')
      // }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/account/login/index')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: '惠科研授权登录'
    },
    component: () => import('@/views/account/login/auth')
  },
  {
    path: '/403',
    name: '403',
    meta: {
      title: '403'
    },
    component: () => import('@/views/system/error/403')
  },
  {
    path: '/500',
    name: '500',
    meta: {
      title: '500'
    },
    component: () => import('@/views/system/error/500')
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/system/error/404')
  }
]
export default routes