import request from '@/plugins/request';

export function HkyOauthAccessToken (params) {
  return request({
      url: process.env.VUE_APP_AUTH_URL + '/hky/oauth/access_token',
      method: 'get',
      params
  });
}
export function HkyScholars (params) {
  return request({
      url: process.env.VUE_APP_AUTH_URL + '/hky/scholars/' + params.user_id,
      method: 'get',
      params
  });
}

export function HkyLogin (data) {
  return request({
      url: '/user/scholar/login',
      method: 'post',
      data
  });
}

export function getAccessTokenByRefreshToken (params) {
  return request({
    url: process.env.VUE_APP_AUTH_URL + '/hky/oauth/refresh_token',
    method: 'get',
    isRefreshToken: true,
    params
  })
}

export function getUserInfo () {
  return request({
    url: '/user/getUserInfo',
    method: 'post'
  })
}
