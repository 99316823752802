<template>
  <div class="layout-footer">
    <!-- <div class="layout-footer-top">
      <span>Home</span>
      <span>|</span>
      <span>Terms of Use</span>
      <span>|</span>
      <span>Disclaimer</span>
      <span>|</span>
      <span>Privacy Policy</span>
      <span>|</span>
      <span>Contact Us</span>
      <span>|</span>
      <span>Report Inappropriate Content</span>
    </div> -->
    <div class="layout-footer-bottom">
      <span>© 2022-2024 中国科学院文献情报中心 版权所有</span>
      <span>|</span>
      <span>京 ICP 备 05002861 号 - 41</span>
      <span>|</span>
      <span><img src="@/assets/img/ga.png" /></span>
      <span>京公网安备 11010802041396 号</span>
    </div>
  </div>
</template>
<script>
</script>
<style lang="less">
</style>