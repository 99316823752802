<template>
  <div class="layout">
    <Header />
    <Content>
      <router-view #default="{ Component }">
        <!-- <keep-alive>
          <component :is="Component" :key="Component.name" v-if="loadRouter" />
        </keep-alive> -->
        <component :is="Component" :key="Component.name" v-if="loadRouter" />
      </router-view>
    </Content>
    <Footer />
  </div>
</template>
<script>
import Header from './header/index'
import Footer from './footer/index'
export default {
  name: 'BasicLayout',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      loadRouter: true,
      // isHome: true, // 是否是首页 用于首页特殊背景颜色
      // isShowSearch: false, // 是否显示顶部搜索框
      // noSearchPaths: ['/home', '/statistics'],
      // timer: null,
      // isWhiteHeader: false
    }
  },
  // watch: {
  //   '$route': {
  //     immediate: true,
  //     handler (to, from) {
  //       const path = to.path
  //       this.monitorRoute(path)
  //     }
  //   }
  // },
  created () {
    // console.log(this.$route)
    // const path = this.$route.path
    // this.monitorRoute(path)
  },
  mounted () {

  },
  methods: {
    // 监听路由变化
    // monitorRoute (path) {
    //   if (path === '/home' || path === '/' || path === '/index') {
    //     this.isHome = true
    //     window.addEventListener('scroll', this.debouncedMonitorNavStyle)
    //   } else {
    //     this.isHome = false
    //     // 移除滚动事件监听
    //     window.removeEventListener('scroll', this.debouncedMonitorNavStyle);
    //   }
    //   if (this.noSearchPaths.includes(path)) {
    //     this.isShowSearch = false
    //   } else {
    //     this.isShowSearch = true
    //   }
    // },
    // debouncedMonitorNavStyle:_.debounce(function() {
    //   this.monitorNavStyle()
    // }, 30),
    // // 监听header样式
    // monitorNavStyle () {
    //   const _this = this
    //   // 判断滚动条距离顶部的距离
    //   const distanceFromTop = window.pageYOffset || document.documentElement.scrollTop;
      // _this.isWhiteHeader = distanceFromTop > 60
    // }
  }
}
</script>