import axios from 'axios';
import { merge } from 'lodash';
import store from '@/store';
import util from '@/libs/util';
import { tansParams, blobValidate } from '@/libs';
import { errorCode } from '@/libs/config';
// import Setting from '@/setting';
import Router from '@/router';
import ViewUIPlus, { Message, Notice } from 'view-ui-plus';
import { saveAs } from 'file-saver';

// 创建一个错误
function errorCreate (msg) {
    // const err = new Error(msg);
    // errorLog(err);
    // throw err;
    try {
        const err = new Error(msg);
        errorLog(err);
        // console.log(err.message); // This will log "null"
    } catch (e) {
        console.error("An error occurred:", e);
    }
}

// 记录和显示错误
function errorLog (err) {
    // 添加到日志
    // store.dispatch('admin/log/push', {
    //     message: '数据请求异常',
    //     type: 'error',
    //     meta: {
    //         error: err
    //     }
    // });
    // 打印到控制台
    if (process.env.NODE_ENV === 'development') {
        util.log.error('>>>>>> Error >>>>>>');
        console.log(err);
    }
    // 显示提示，可配置使用 View UI Plus 的 $Message 还是 $Notice 组件来显示
    // if (Setting.request.errorModalType === 'Message') {
    //     Message.error({
    //         content: err.message,
    //         duration: 3
    //     });
    // } else if (Setting.request.errorModalType === 'Notice') {
    //     Notice.error({
    //         title: '提示',
    //         desc: err.message,
    //         duration: 3
    //     });
    // }
    Message.error({
        content: err.message,
        duration: 3
    });
}

function requestConfig (config, util) {
    // 在请求发送之前做一些处理
    if (config.params && config.params.isExportFile) {
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8;'
      config.responseType = 'blob'
    }

    // 在需要走apiFox的接口上加上 mock: true,
    // 会改变baseUrl代理到apiFox的接口，并重写baseURL为VUE_APP_API_HOST
    if (config.mock) {
      config.baseURL = process.env.VUE_APP_MOCK_API_HOST
    }
    return config
}

// 默认content-type
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    timeout: 30000,
    // 跨域请求是否需要凭证
    withCredentials: true
});

// 不需携带token的接口地址
const noTokenUrl = [ '/home/data' ];

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false;

        // 除白名单外，其余接口请求附加token
        const token = util.cookies.get('token');
        if (
            token &&
            !noTokenUrl.includes(config.url) &&
            !isToken
        ) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        if (config.isRefreshToken) {
          const refreshToken = util.cookies.get('refresh_token');
          config.headers['Authorization'] = 'Bearer ' + refreshToken;
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        return merge(config, requestConfig(config, util));
    },
    (error) => {
        // 发送失败
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data;
        // 这个状态码是和后端约定的
        const { code } = dataAxios;
        // 根据 code 进行判断
        if (code === undefined) {
            // 如果没有 code 代表这不是项目后端开发的接口
            return dataAxios;
        } else {
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
                case 200:
                    // store.dispatch('admin/account/refreshAccessToken')
                    return dataAxios.data;
                case 401:
                    Message.error({
                        content: '登录凭证过期',
                        duration: 3
                    });
                    store.dispatch('admin/account/logout', {
                        confirm: false,
                        expired: true,
                        vm: {}
                    });
                    break;
                case 404:
                    Router.replace({ name: '404' });
                    break;
                // case 'xxx':
                //     // [ 示例 ] 其它和后台约定的 code
                //     errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
                //     break;
                case 500:
                    // Router.replace({ name: '500' });
                    // if (dataAxios.msg === '验证码错误') {
                    //     Router.replace({ name: 'login' });
                    //     Message.error({
                    //         content: dataAxios.msg,
                    //         duration: 3
                    //     });
                    //     break;
                    // }
                    errorCreate(`${dataAxios.msg}`);
                    break;
                default:
                    // 不是正确的 code
                    errorCreate(`${dataAxios.msg}`);
                    break;
            }
        }
    },
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        errorLog(error);
        return Promise.reject(error);
    }
);

// 下载接口
export function download (url, params, filename) {
  ViewUIPlus.LoadingBar.start();
  return service.post(url, params, {
      transformRequest: [ (params) => {
          return tansParams(params)
      } ],
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob'
  }).then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
          const blob = new Blob([ data ])
          saveAs(blob, filename)
      } else {
          const resText = await data.text();
          const rspObj = JSON.parse(resText);
          const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
          Message.error(errMsg);
      }
      ViewUIPlus.LoadingBar.finish();
  }).catch((err) => {
      console.error(err)
      Message.error('下载文件出现错误，请联系管理员！')
      ViewUIPlus.LoadingBar.finish();
  })
}

// 下载接口 get方式
export function getDownload (url, filename) {
  ViewUIPlus.LoadingBar.start();
  return service.get(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob'
  }).then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
          const blob = new Blob([ data ])
          saveAs(blob, filename)
      } else {
          const resText = await data.text();
          const rspObj = JSON.parse(resText);
          const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
          Message.error(errMsg);
      }
      ViewUIPlus.LoadingBar.finish();
  }).catch((err) => {
      console.error(err)
      Message.error('下载文件出现错误，请联系管理员！')
      ViewUIPlus.LoadingBar.finish();
  })
}

/***********************
 * 下载excel 通用方法
 * @param params
 *  uri: 接口地址
 *  data： 接口参数
 *  name: 表格名称
 *  noDate: 不要自动添加时间
 *
 */
export function exportExcelHandler (url, params, filename) {
    ViewUIPlus.LoadingBar.start();
    return service.post(url, params, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        responseType: 'arraybuffer'
    }).then(async (data) => {
        const isLogin = await blobValidate(data);
        if (isLogin) {
            const blob = new Blob([ data ])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            Message.error(errMsg);
        }
        ViewUIPlus.LoadingBar.finish();
    }).catch((err) => {
        console.error(err)
        Message.error('下载文件出现错误，请联系管理员！')
        ViewUIPlus.LoadingBar.finish();
    })
}

export default service;
