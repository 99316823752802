/**
 * 注册、登录、注销
 * */
import util from '@/libs/util'
import router from '@/router'
// import Setting from '@/setting.js'
import dayjs from 'dayjs'
const cookiesExpires = 1

// import {
//   AccountLogin,
//   AccountRegister,
//   getUserInfo,
//   AccountLogout,
//   getAccessTokenByRefreshToken
// } from '@/api/account'
import { HkyLogin, getAccessTokenByRefreshToken, getUserInfo } from '@/api/hky'

import { Modal } from 'view-ui-plus'
export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     */
    // login ({ dispatch }, data = {}) {
    //   return new Promise((resolve, reject) => {
    //     // 开始请求登录接口
    //     AccountLogin(data)
    //       .then(async (res) => {
    //         // 设置 cookie 一定要存 uuid 和 token 两个 cookie
    //         // 整个系统依赖这两个数据进行校验和存储
    //         // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
    //         // token 代表用户当前登录状态 建议在网络请求中携带 token
    //         // 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
    //         // 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
    //         util.cookies.set('token', res.access_token)
    //         util.cookies.set('refresh_token', res.refresh_token)
    //         // 过期时间提前12小时
    //         const expiration = dayjs().add(Setting.cookiesExpires * 24 - 12, 'hour').valueOf()
    //         // dayjs(expiration).isAfter(new Date().getTime())
    //         util.cookies.set('expiration_time', expiration)

    //         // 获取动态菜单
    //         // if (Setting.dynamicMenu) {
    //         //   await dispatch('admin/menu/getMenuList', true, { root: true })
    //         // }
    //         // 设置用户 uuid 和 tenantId
    //         await getUserInfo().then((res) => {
    //           util.cookies.set('uuid', res.user.userId.toString())
    //         })
    //         // 结束
    //         resolve()
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    hkyLogin ({ dispatch }, data = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        HkyLogin(data)
          .then(async (res) => {
            if (!res) {
              return
            }
            util.cookies.set('token', res.access_token)
            util.cookies.set('refresh_token', res.access_token)

            // 过期时间提前12小时
            const expiration = dayjs().add(cookiesExpires * 24 - 12, 'hour').valueOf()
            // dayjs(expiration).isAfter(new Date().getTime())
            util.cookies.set('expiration_time', expiration)

            // 获取动态菜单
            // if (Setting.dynamicMenu) {
            //   await dispatch('admin/menu/getMenuList', true, { root: true })
            // }
            // 设置用户 uuid 和 tenantId
            await getUserInfo().then(async (res) => {
              const info = {
                access: res.permissions, // 用户权限，参见 Admin Plus 的「鉴权」文档
                name: res.user.nickName,
                avatar:
                  res.user.avatar === '' || res.user.avatar === null
                    ? 'https://rzht.oss-cn-beijing.aliyuncs.com/logo/logo.png'
                    : `${res.user.avatar}`,
                roles:
                  res.roles && res.roles.length > 0
                    ? res.roles
                    : ['ROLE_DEFAULT'], // 返回的roles是空数组，则赋默认值
                moreInfo: res.user
              }
              util.cookies.set('uuid', res.user.userId.toString())
              util.cookies.set('userName', res.user.nickName.toString())
              await dispatch('admin/user/set', info, { root: true })
            })
            // 结束
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
     /**
     * @description 刷新token
     */
    refreshAccessToken () {
      const refreshToken = util.cookies.get('refresh_token')
      const localExpiration = util.cookies.get('expiration_time')
      if (refreshToken && dayjs(localExpiration).isAfter(new Date().getTime())) {
        return new Promise((resolve, reject) => {
          // 开始请求登录接口
          getAccessTokenByRefreshToken()
            .then(async (res) => {
              util.cookies.set('token', res.access_token)
              util.cookies.set('refresh_token', res.refresh_token)
              const expiration = dayjs().add(cookiesExpires * 24 - 12, 'hour').valueOf()

              util.cookies.set('expiration_time', expiration)
              // 结束
              resolve()
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        if (!refreshToken) {
          router.push('/login')
        }
      }
    },
    /**
     * 获取并存储当前用户信息
     * @param {*} param0
     * @returns
     */
    getUserInfo ({ dispatch }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(async (res) => {
            // 存储用户info
            const info = {
              access: res.permissions, // 用户权限，参见 Admin Plus 的「鉴权」文档
              name: res.user.userName,
              avatar:
                res.user.avatar === '' || res.user.avatar === null
                  ? 'https://rzht.oss-cn-beijing.aliyuncs.com/logo/logo.png'
                  : `${res.user.avatar}`,
              roles:
                res.roles && res.roles.length > 0
                  ? res.roles
                  : ['ROLE_DEFAULT'], // 返回的roles是空数组，则赋默认值
              moreInfo: res.user
            }

            // dispatch('admin/layout/updateWaterMarkText', res.user.userName, {
            //   root: true
            // })
            // if (Setting.dynamicMenu) {
            //   await dispatch('admin/menu/getMenuList', true, { root: true })
            // }
            await dispatch('admin/user/set', info, { root: true })
            // 用户登录后从持久化数据加载一系列的设置
            // await dispatch('load', {
            //   loadOpenedTabs: Setting.page.loadOpenedTabs
            // })
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * @description 退出登录
     * */
    logout (
      { commit, dispatch },
      { confirm = false, vm, expired = false } = {}
    ) {
      async function logout () {
        // AccountLogout()
        //   .then(async () => {
        //     if (window.socket) {
        //       console.log('主动断开')
        //       window.socket.close()
        //     }
        //     // 删除cookie
        //     util.cookies.remove('token')
        //     util.cookies.remove('refresh_token')
        //     util.cookies.remove('uuid')
        //     util.cookies.remove('expiration_time')

        //     // 清空 vuex 用户信息
        //     // await dispatch('admin/user/set', {}, { root: true })
        //     // 跳转路由
        //     // router.push({
        //     //   name: 'login'
        //     // })
        //     const url = process.env.VUE_APP_AUTH_URL + '/hky/oauth/logout?appid=' + process.env.VUE_APP_AUTH_APP_ID + '&redirect_uri=' + encodeURI(location.origin + process.env.VUE_APP_PUBLIC_PATH)
        //     location.href = url
        //   })
        //   .catch((err) => console.error(err))
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('refresh_token')
        util.cookies.remove('uuid')
        util.cookies.remove('expiration_time')
        const url = process.env.VUE_APP_AUTH_URL + '/hky/oauth/logout?appid=' + process.env.VUE_APP_AUTH_APP_ID + '&redirect_uri=' + encodeURI(location.origin + process.env.VUE_APP_PUBLIC_PATH)
        location.href = url
      }
      if (expired) {
        if (window.socket) {
          console.log('主动断开')
          window.socket.close()
        }
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('refresh_token')
        util.cookies.remove('uuid')
        // 清空 vuex 用户信息
        // dispatch('admin/user/set', {}, { root: true })
        // 跳转路由
        router.push({
          name: 'login'
        })
      } else {
        if (confirm) {
          Modal.confirm({
            title: vm.$t('basicLayout.logout.confirmTitle'),
            content: vm.$t('basicLayout.logout.confirmContent'),
            onOk () {
              logout()
            }
          })
        } else {
          logout()
        }
      }
    },
    /**
     * @description 注册
     * @param context {Object}
     * @param mail {String} 邮箱
     * @param password {String} 密码
     * @param mobile {String} 手机号码
     * @param captcha {String} 验证码
     */
    // register (
    //   { dispatch },
    //   { mail = '', password = '', mobile = '', captcha = '' } = {}
    // ) {
    //   return new Promise((resolve, reject) => {
    //     // 开始请求登录接口
    //     AccountRegister({
    //       mail,
    //       password,
    //       mobile,
    //       captcha
    //     })
    //       .then(async (res) => {
    //         // 注册成功后，完成与登录一致的操作
    //         // 注册也可视情况不返还 uuid、token 等数据，在注册完成后，由前端自动执行一次登录逻辑
    //         util.cookies.set('uuid', res.uuid)
    //         util.cookies.set('token', res.token)
    //         // 设置 vuex 用户信息
    //         // await dispatch('admin/user/set', res.info, { root: true })
    //         // 用户登录后从持久化数据加载一系列的设置
    //         // await dispatch('load')
    //         // 结束
    //         resolve()
    //       })
    //       .catch((err) => {
    //         reject(err)
    //       })
    //   })
    // },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     * @param {Object} loadOpenedTabs 是否加载页签信息
     */
    // load ({ state, dispatch }, { loadOpenedTabs = true } = {}) {
    //   return new Promise(async (resolve) => {
    //     // 加载用户登录信息
    //     await dispatch('admin/user/load', null, { root: true })
    //     // 加载用户持久化布局
    //     await dispatch('admin/layout/loadLayoutSetting', {}, { root: true })
    //     // 持久化数据加载上次退出时的多页列表
    //     await dispatch(
    //       'admin/page/openedLoad',
    //       { loadOpenedTabs },
    //       { root: true }
    //     )
    //     // end
    //     resolve()
    //   })
    // }
  }
}
